<div>
  <div class="label-container">
    <label *ngIf="label !== ''">{{ label }}</label>
    <ng-content select="[labelItem]"></ng-content>
  </div>

  <div class="input-container" [ngClass]="{ 'input-container-relative': startContentExist }">
    <div
      [ngStyle]="{ 'margin-top': label !== '' ? '10px' : '0' }"
      [ngClass]="{ 'start-content': startContentExist }"
      #startContent>
      <ng-content select="[start]"></ng-content>
    </div>
    <input
      #input
      (keydown)="onKeyDown($event)"
      (keyup)="onKeyUp($event)"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      [ngClass]="{
        'outline-danger': invalid,
        'outline-primary': !invalid,
        'no-spin-button': !spinButton
      }"
      [ngStyle]="{
        'font-family': fontFamily,
        'font-size': fontSize,
        width: width,
        padding: padding,
        'margin-top': label !== '' ? '10px' : '0',
        background: 'var(--app-' + background + '-color)',
        'padding-left': startContentExist ? '30px' : '20px',
        'padding-right': endContentExist && invalid ? '73px' : endContentExist || invalid ? '50px' : ''
      }"
      [name]="name"
      [readonly]="readonly"
      [value]="value"
      [disabled]="disable"
      [placeholder]="placeholder"
      (input)="onInput($event)"
      [type]="type === 'number' ? '' : type"
      [autocomplete]="autocomplete ? 'on' : 'new-password'" />
    <div class="end-icons" [ngStyle]="{ 'margin-top': label !== '' ? '10px' : '0' }">
      <em
        tooltip
        *ngIf="invalid && messageErrorsType === 'tooltip'"
        class="bx bx-error-circle"
        [ngStyle]="{
          'font-size': '25px',
          'margin-left': '-40px',
          color: 'var(--app-danger-color)'
        }">
        <app-tooltip-content [width]="tooltipErrorWidth" [showOrigin]="tooltipErrorShowOrigin">
          <ng-container inside *ngTemplateOutlet="errorValidators"></ng-container>
        </app-tooltip-content>
      </em>
      <div
        [ngClass]="{
          'end-content': endContent.children.length > 0,
          'end-content-valid': endContent.children.length > 0 && !invalid,
          'end-content-invalid': endContent.children.length > 0 && invalid
        }"
        #endContent>
        <ng-content select="[end]"></ng-content>
      </div>
    </div>
  </div>
</div>

<div *ngIf="messageErrorsType === 'classic' && invalid">
  <ng-container *ngTemplateOutlet="errorValidators"></ng-container>
</div>

<ng-template #errorValidators>
  <ng-container *ngFor="let error of errors">
    <div [ngSwitch]="error">
      <p *ngSwitchCase="'required'" class="msg-validator">
        {{ errorMessages[error] || fieldName + ' is required.' }}
      </p>
      <p *ngSwitchCase="'minlength'" class="msg-validator">
        {{
          errorMessages[error] ||
            fieldName +
              ' must have at least ' +
              controlErrors[error].requiredLength +
              '' +
              (type === 'number' || type === 'positiveNumber' || type === 'decimal' ? ' digits' : ' characters') +
              '.'
        }}
      </p>
      <p *ngSwitchCase="'maxlength'" class="msg-validator">
        {{
          errorMessages[error] ||
            fieldName +
              ' must have max ' +
              controlErrors[error].requiredLength +
              '' +
              (type === 'number' || type === 'positiveNumber' || type === 'decimal' ? ' digits' : ' characters') +
              '.'
        }}
      </p>
      <p *ngSwitchCase="'emailMatch'" class="msg-validator">
        {{ errorMessages[error] || 'Email format is invalid.' }}
      </p>
      <p *ngSwitchCase="'email'" class="msg-validator">
        {{ errorMessages[error] || 'Email format is invalid.' }}
      </p>
      <p *ngSwitchCase="'contains'" class="msg-validator">
        {{ errorMessages[error] || 'Contains invalid values.' }}
      </p>
      <p *ngSwitchCase="'pattern'" class="msg-validator">
        {{ errorMessages[error] || fieldName + " doesn't have a valid format." }}
      </p>
      <p *ngSwitchDefault class="msg-validator">
        {{ errorMessages[error] || error + ' message not assigned.' }}
      </p>
    </div>
  </ng-container>
</ng-template>
