<nav class="nav">
  <div class="nav-container">
    <div class="logo-autocomplete-container">
      <img class="nav-container-logo" src="/assets/images/CE-TV-logo.png" alt="logo"
        (click)="logBackSalesRepAccount()" />
      <app-autocomplete
        *ngIf="(urlsMain.includes(router.url) || (router.url | matchUrl: urlsMain))"
        [(ngModel)]="advertiserAccountId"
        (ngModelChange)="changeAdvertiserAccount($event)"
      >
        <app-autocomplete-option
          *ngFor="let account of advertiserAccounts"
          [name]="account.company_name"
          [value]="account.account_id"></app-autocomplete-option>
      </app-autocomplete>
      <div class="plus-icon" tooltip (click)="openAdvertiserAccountModal()"
        *ngIf="(urlsMain.includes(router.url) || (router.url | matchUrl: urlsMain))">
        <app-tooltip-content [showOrigin]="false" moveToTop="82px">
          <p [ngStyle]="{ 'font-size': '13px' }">Create a new advertiser account</p>
        </app-tooltip-content>
        <em class="bx bx-plus"></em>
      </div>
    </div>

    <div
      class="nav-mobile"
      *ngIf="(urlsMain.includes(router.url) || (router.url | matchUrl: urlsMain)) && uiService.showMenu">
      <div class="nav-mobile-button dropdown" (click)="showMobileItems = true" *ngIf="showMobileItems === false">
        <em class="nav-mobile-icon bx bx-menu"></em>
      </div>
      <div
        class="nav-mobile-button dropdown"
        (click)="showMobileItems = false"
        *ngIf="showMobileItems && showPrincipalMobileRoutes">
        <em class="nav-mobile-icon bx bx-x"></em>
      </div>
      <!-- (click)="closeSubRoutes()" -->
      <div class="nav-mobile-button dropdown" *ngIf="showMobileItems && showPrincipalMobileRoutes === false">
        <p>Back</p>
      </div>
      <div
        class="nav-mobile-items dropdown-content dropdown-content-mobile animated fadeIn"
        *ngIf="showMobileItems === true">
        <div
          class="background-primary"
          *ngIf="(urlsMain.includes(router.url) || (router.url | matchUrl: urlsMain)) && uiService.showMenu">
          <ng-container *ngFor="let route of routes">
            <a
              *ngIf="showPrincipalMobileRoutes"
              [ngClass]="{
                route: true,
                button: true,
                'background-primary': true,
                'text-light': true,
                'font-weight-bold':
                  router.url.includes(route.url) ||
                  (route.associatedUrls && (router.url | matchUrl: route.associatedUrls))
              }"
              (click)="navigateMobile(route)">
              {{ route.name }}
            </a>
            <div *ngIf="showPrincipalMobileRoutes === false && route.subRouteInfo && route.subRouteInfo.openMobile">
              <p class="route sub_route-title text-align-center text-light">{{ route.name }}</p>
              <a
                (click)="navigateMobile(subRoute)"
                [ngClass]="{
                  route: true,
                  button: true,
                  'background-primary': true,
                  'text-light': true,
                  'font-weight-bold': router.url.includes(subRoute.url),
                  'font-weight-500': !router.url.includes(subRoute.url)
                }"
                *ngFor="let subRoute of route.subRouteInfo.subRoutes">
                {{ subRoute.name }}
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="nav-web">
      <div
        *ngIf="(urlsMain.includes(router.url) || (router.url | matchUrl: urlsMain)) && uiService.showMenu"
        class="flex space-between">
        <div *ngFor="let route of routes">
          <a
            [ngClass]="{
              button: true,
              'current-tab':
                router.url.includes(route.url) || (route.associatedUrls && (router.url | matchUrl: route.associatedUrls))
            }"
            (click)="navigate(route)"
            (mouseenter)="toggleSubRoute(route, 'open')"
            (mouseleave)="toggleSubRoute(route, 'close')">
            {{ route.name }}
            <app-dropdown-container
              *ngIf="route.subRouteInfo"
              [isOpen]="route.subRouteInfo.openDesktop"
              (closeDropdown)="route.subRouteInfo.openDesktop = false"
              (openDropdown)="route.subRouteInfo.openDesktop = true">
              <div>
                <em class="bx bx-chevron-down font-weight-bold"></em>
                <div class="dropdown-content dropdown-content-subroutes pointer" *ngIf="route.subRouteInfo.openDesktop">
                  <div
                    *ngFor="let subRoute of route.subRouteInfo.subRoutes"
                    (click)="navigate(subRoute, $event)"
                    [ngClass]="{
                      button: true,
                      'text-dark': !router.url.includes(subRoute.url),
                      'text-light': router.url.includes(subRoute.url)
                    }"
                    class="background-light border-bottom-light-gray">
                    {{ subRoute.name }}
                  </div>
                </div>
              </div>
            </app-dropdown-container>
          </a>
          <figure class="orange-decorator">
            <img  src="../../../../assets/images/orange-decorator.png" alt="line"
              [width]="calculateDecoratorWidth(route)" height="6"
              *ngIf="router.url.includes(route.url) ||
                (route.associatedUrls && (router.url | matchUrl: route.associatedUrls))"
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
</nav>
