<section class="modal-background">
  <app-card>
    <div class="flex row space-between">
      <h3>NEW ADVERTISER ACCOUNT</h3>
      <em class="bx bx-x" (click)="closeModal()"></em>
    </div>

    <form [formGroup]="advertiserAccountForm">
      <div class="flex row gap-10">
        <app-item>
          <app-input label="First Name *" fieldName="First Name" formControlName="first_name"></app-input>
        </app-item>
        <app-item>
          <app-input label="Last Name *" fieldName="Last Name" formControlName="last_name"></app-input>
        </app-item>
      </div>

      <app-item>
        <app-input label="Company *" fieldName="Company" formControlName="company"></app-input>
      </app-item>
      <app-item>
        <app-input label="Email *" fieldName="Email" formControlName="email"></app-input>
      </app-item>

      <app-item class="flex flex-center">
        <app-button padding="10px 32px"
          background="dark"
          textColor="light"
          width="auto"
          [disable]="advertiserAccountForm.invalid"
          (click)="createAdvertiserAccount()">
          CREATE ACCOUNT
          <app-spinner
            size="25px"
            color="light"
            borderWidth="3px"
            marginLeft="10px"
            *ngIf="creatingAdvertiserAccount"></app-spinner>
        </app-button>
      </app-item>

    </form>
  </app-card>
</section>
