import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent {
  @ViewChild('optionContainer') optionContainer!: HTMLParagraphElement;
  @Input() value!: string | null;
  @Input() name!: string;
  multiple = false;
  valueParent: any | any[];
  selected!: boolean;
  over!: boolean;
  hidden = false;

  @Output() itemSelected = new EventEmitter();

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  change() {
    this.itemSelected.emit(this.value);
  }
}
