import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdvertiserAccountController } from '../../services/advertiser-account-controller.service';
import { AlertController } from '../../../alert/services/alert-controller.service';
import { SalesPortalService } from 'src/app/shared/services/sales-portal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-advertiser-account-form',
  templateUrl: './advertiser-account-form.component.html',
  styleUrls: ['./advertiser-account-form.component.scss']
})
export class AdvertiserAccountFormComponent {
  advertiserAccountForm: FormGroup;
  creatingAdvertiserAccount: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private advertiserAccountController: AdvertiserAccountController,
    private alertController: AlertController,
    private salesPortalService: SalesPortalService,
    private navigationService: NavigationService,
  ) {
    this.advertiserAccountForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  createAdvertiserAccount() {
    this.creatingAdvertiserAccount = true;
    this.advertiserAccountController.createAdvertiserAccount({
      company_name: this.advertiserAccountForm.value.company,
      sales_rep_id: localStorage.getItem('user_id') || '',
      use_checkout: true,
    }).subscribe({
      next: (resp: any) => {
        this.createAdvertiserUser(resp.account_id);
      },
      error: (err) => {
        this.creatingAdvertiserAccount = false;
        this.alertController.handleError({
          msg: err.error.message || 'Error creating the new advertiser account',
        });
      }
    });
  }

  createAdvertiserUser(account_id: string) {
    this.advertiserAccountController.createAdvertiserUser({
      account_id,
      email_address: this.advertiserAccountForm.value.email,
      first_name: this.advertiserAccountForm.value.first_name,
      last_name: this.advertiserAccountForm.value.last_name,
      type: 'individual',
      platforms: ['cetv-ads'],
    }).subscribe({
      next: (resp: any) => {
        this.getAdvertiserAccounts();
        this.creatingAdvertiserAccount = false;
        this.confirmCreationAndCloseModal(account_id);
      },
      error: (err) => {
        let error = '';
        if (err.error.message.includes('index: email_address')) {
          error = 'The email address is already registered.'
        } else error = 'Error creating the advertiser user';
        this.creatingAdvertiserAccount = false;
        this.alertController.handleError({
          msg: error,
        });
      }
    });
  }

  confirmCreationAndCloseModal(account_id: string) {
    this.alertController.confirmAction({
      msg: 'Do you want to switch to the new account?',
      title: 'Account created successfully',
      iconName: 'bx bxs-check-circle',
      iconColor: 'success',
      confirmText: 'Yes',
      confirmFunction: () => {
        this.salesPortalService.loginAdvertiserAccount(account_id).subscribe({
          next: (res: any) => {
            localStorage.setItem('advertiser_account_id', account_id);
            localStorage.setItem('company_name', this.advertiserAccountForm.value.company);
            localStorage.setItem('token', res.token);
            localStorage.setItem('advertiser_user_id', res.id);
            this.navigationService.navigateToDashboard();
          },
          error: (err: any) => {
            this.alertController.handleError({
              msg: err.error?.message || 'Error getting the advertiser account',
            });
          }
        });
      },
      cancelText: 'No',
      cancelFunction: () => {
        window.location.reload();
      }
    });
  }

  closeModal() {
    this.advertiserAccountController.closeAdvertiserAccountForm();
  }

  getAdvertiserAccounts() {
    const salesRep = this.salesPortalService.advertisersList.find(
      (advertiser: any) => advertiser.account_id === localStorage.getItem('user_id'));

    this.salesPortalService.getAdvertisers(salesRep.account_id).subscribe({
      next: (resp: any) => {
        resp.unshift({
            account_id: salesRep.account_id,
            company_name: `${salesRep.company_name}`
          });
        this.salesPortalService.setAdvertisersList(resp);
      },
      error: (err) => {
        const msg = err.error.message || 'There was an error getting the advertiser accounts';
        this.alertController.handleError({ msg });
      },
    });
  }
}
