<span
  #tooltipContent
  class="tooltip"
  *ngIf="showTooltip"
  [ngClass]="{
    tooltipFadeIn: true,
    'tooltip-origin': showOrigin,
    'tooltip-box-shadow': boxShadow
  }"
  [ngStyle]="{ left: left, width: width, top: top }">
  <div
    [ngStyle]="{
      'max-height': maxHeightContent,
      height: heightContent
    }">
    <ng-content></ng-content>
  </div>
</span>
