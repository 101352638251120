import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputComponent } from '../../../input/components/input/input.component';

import { AutocompleteOptionComponent } from '../autocomplete-option/autocomplete-option.component';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  /**Access the autocomplete option components*/
  @ContentChildren(AutocompleteOptionComponent) content!: QueryList<AutocompleteOptionComponent>;

  /**Access the input container that has inside the input copmponent*/
  @ViewChild('inputContainer') inputContainer!: ElementRef<HTMLDivElement>;
  /**Access the input component*/
  @ViewChild(InputComponent) inputComponent!: InputComponent;
  /**Access the list container that has select options components*/
  @ViewChild('listContainer') listContainer!: ElementRef<HTMLUListElement>;

  /**Tells the parent when value changes */
  @Output() valueChange = new EventEmitter();
  /**Tells the parent to filter on click icon or press enter */
  @Output() clickFilter = new EventEmitter();

  /**Sets the placeholder of the input component */
  @Input() placeholder = '';
  /**Stores the initial place holder */
  defaultPlaceholder = '';

  /**Sets the label of the autocomplete */
  @Input() label = '';
  /**Set the form field name of the autocomplete to show in errors messages */
  @Input() fieldName = '';
  /**Detect when value is changed in input */
  @Input('value') set valueHandler(value: any | any[]) {
    this.value = value;
    this.updateValueInChilds();
  }
  /**Autocomplete value */
  value: any | any[];
  /**Sets if the Autocomplete is going to be of multiple selection */
  @Input() multiple = false;
  /**Sets the select readonly attribute */
  @Input() readonly = false;
  /**Sets the disable readonly attribute */
  @Input() disable = false;
  /** to know if show errors under select or in a tooltip*/
  @Input() messageErrorsType: 'classic' | 'tooltip' = 'tooltip';
  /** variable to show tooltip error origin*/
  @Input() tooltipErrorShowOrigin = true;
  /** variables to set tooltip items  size*/
  @Input() tooltipItemsWidth = '250px';
  @Input() tooltipItemsMaxHeightContent = '250px';
  /** flag to show tooltip items selected*/
  @Input() showTooltipItems = false;
  /** variable to show tooltip items origin*/
  @Input() tooltipItemsShowOrigin = false;
  /**Flag to know if show items selected count */
  @Input() showItemsCount = false;
  /**Flag to know if select its using pagination */
  @Input() usingPagination = false;

  /**Reactive form control name */
  @Input() formControlName = '';
  /**Items name */
  @Input() itemsName = 'item';
  /**Custom error messages to reactive forms validators */
  @Input() errorMessages: any = {};
  /**Flag to know if show error message inside component */
  @Input() showErrorMessages = true;
  /**Stores errors of reactive form */
  @Input() errors: any = [];

  /**Variable to show or hide select options */
  showOptions = false;
  /**Flag to set if there are items in the select or not */
  noItems = false;
  /**Stores the selected items */
  selectedItems: any[] = [];
  /**Flag to set if there are items in the search results */
  noResults = false;
  /**Subscriptions */
  selectOptionChangesSub = new Subscription();
  itemsSelectedSub: Subscription[] = [];
  /**Reactive form control errors to show them in the select*/
  controlErrors!: any;
  /**Reactive form control value sub */
  valueSub: Subscription | undefined = new Subscription();
  /**Reactive form control status sub */
  statusSub: Subscription | undefined = new Subscription();
  /**To positionate in item when navigating with keys */
  indexItemSelected = -1;
  /**Flag to know if select is focused */
  focused = false;
  /**Flag to know if current item exist in list */
  itemSelectedDontExist = false;

  /**
   *
   * @param controlContainer Reactive form control container
   * @param changeDetectroRef change detector service
   * @param eRef native element ref
   */
  constructor(
    @Optional() @Host() @SkipSelf() public controlContainer: ControlContainer,
    private changeDetectroRef: ChangeDetectorRef,
    private eRef: ElementRef
  ) {}

  /**
   * get distance of the select to screen bottom
   */
  get distanceToBottom() {
    const rect = this.inputContainer.nativeElement.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    return bodyRect.bottom - rect.bottom;
  }

  /**
   * Invalid state for form control
   */
  get invalid() {
    return (
      this.showErrorMessages &&
      this.errors.length > 0 &&
      this.controlContainer.control?.get(this.formControlName)?.invalid &&
      this.controlContainer.control?.get(this.formControlName)?.touched
    );
  }

  /**
   * toggles add event listeners
   * @param action tells to add or remove
   */
  toggleListeners(action: 'add' | 'remove') {
    this.toggleNavigateItemsListener(action);
    this.toggleWheelListener(action);
    this.toggleTouchListener(action);
  }

  /**
   * On mouse down outside hide picker and on click inside touch
   * @param event on mouse down event
   */
  @HostListener('document:mousedown', ['$event'])
  click(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
      if (this.focused) {
        this.onTouch();
        this.focused = false;
      }
      this.toggleListeners('remove');
    } else {
      if (!this.disable && !this.readonly) {
        this.showOptions = true;
        this.focused = true;
        this.toggleListeners('add');
      }
    }
  }

  /**
   * Touch listener add to hide the picker or remove for better performance
   * @param action add or remove on touch listener
   */
  toggleTouchListener(action: 'add' | 'remove') {
    if (action === 'add') {
      document.addEventListener('touchstart', this.onTouchListener);
    } else {
      document.removeEventListener('touchstart', this.onTouchListener);
    }
  }

  onTouchListener = (event: any) => {
    if (this.listContainer) {
      if (!this.listContainer.nativeElement.contains(event.target)) {
        this.showOptions = false;
        this.toggleTouchListener('remove');
      }
    }
  };
  /**
   * Wheel listener add to hide the picker or remove for better performance
   * @param action add or remove on Wheel listener
   */
  toggleWheelListener(action: 'add' | 'remove') {
    if (action === 'add') {
      document.addEventListener('wheel', this.onWheelListener);
    } else {
      document.removeEventListener('wheel', this.onWheelListener);
    }
  }

  onWheelListener = (event: any) => {
    if (this.listContainer) {
      if (!this.listContainer.nativeElement.contains(event.target)) {
        this.showOptions = false;
        this.toggleWheelListener('remove');
      }
    }
  };

  /**
   * Keydown listener add to navigate between options or remove for better performance
   * @param action add or remove on Keydown listener
   */
  toggleNavigateItemsListener(action: 'add' | 'remove') {
    if (action === 'add') {
      this.inputContainer.nativeElement.addEventListener('keydown', this.onNavigate);
    } else {
      this.inputContainer.nativeElement.removeEventListener('keydown', this.onNavigate);
    }
  }

  onNavigate = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      this.showOptions = false;
    }
    if (this.inputComponent.isFocused) {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.arrowDownPressedOnNavigate();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.arrowUpPressedOnNavigate();
      } else if (event.key === 'Enter') {
        event.preventDefault();
        const itemsFiltered = this.content.filter((item) => item.hidden === false);

        const itemSelected = itemsFiltered.find((item) => item.over === true);

        this.content.forEach((item) => {
          if (item.value === itemSelected?.value) {
            item.over = true;
            item.change();
          }
        });
      }
    } else {
      this.showOptions = false;
    }
  };

  /**
   * Executes when the user is navigating with the keys
   */
  arrowDownPressedOnNavigate() {
    const itemsFiltered = this.content.filter((item) => item.hidden === false);

    if (this.indexItemSelected + 1 < itemsFiltered.length) {
      this.indexItemSelected++;
    } else {
      this.indexItemSelected = 0;
    }

    itemsFiltered.forEach((item, i) => {
      if (i === this.indexItemSelected) {
        item.over = true;
        this.listContainer.nativeElement.scrollTop = 39 * i;
      } else {
        item.over = false;
      }
    });
    const itemSelected = itemsFiltered.find((item) => item.over === true);

    this.content.forEach((item) => {
      if (item.value === itemSelected?.value) {
        item.over = true;
      }
    });
  }

  /**
   * Executes when the user is navigating with the keys
   */
  arrowUpPressedOnNavigate() {
    const itemsFiltered = this.content.filter((item) => item.hidden === false);
    if (this.indexItemSelected > 0) {
      this.indexItemSelected--;
    } else {
      this.indexItemSelected = itemsFiltered.length - 1;
    }

    itemsFiltered.forEach((item, i) => {
      if (i === this.indexItemSelected) {
        item.over = true;
        this.listContainer.nativeElement.scrollTop = 39 * i;
      } else {
        item.over = false;
      }
    });

    const itemSelected = itemsFiltered.find((item) => item.over === true);

    this.content.forEach((item) => {
      if (item.value === itemSelected?.value) {
        item.over = true;
      }
    });
  }

  /**
   * Control value accesor interface methods
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: any) => {
    //not implemented
  };
  onTouch: any = () => {
    //not implemented
  };

  writeValue(value: any) {
    this.value = value;
    this.updateValueInChilds();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onFocus() {
    if (!this.disable && !this.readonly) {
      this.showOptions = true;
      this.focused = true;
      this.toggleWheelListener('add');
    }
  }

  onBlur() {
    if (this.focused) {
      this.onTouch();
      this.focused = false;
    }
    if (!this.multiple && !this.usingPagination) {
      const item = this.content.find((itemObject) => this.value === itemObject.value);
      if (item) {
        this.selectItem({ item, preventOnChange: true });
      }
    }
  }

  /**
   * on key up event
   * @param event key up event
   */
  onKeyUp(event: any) {
    if (event.key === 'Enter' && this.usingPagination) {
      this.filterOnClick();
    }
    if (event.key === 'Backspace' && event.target.value === '' && this.usingPagination) {
      this.showOptions = true;
      this.clickFilter.emit('');
    }
  }

  /**
   * On init component
   */
  ngOnInit(): void {
    this.defaultPlaceholder = this.placeholder;
  }
  /**
   * After component init component
   */
  ngAfterViewInit() {
    this.inputComponent.placeholder = this.placeholder;
    this.configureAutoComplete();
    this.selectOptionChangesSub = this.content.changes.subscribe(() => {
      this.itemsSelectedSub.forEach((sub) => {
        sub.unsubscribe();
      });
      this.itemsSelectedSub = [];
      this.configureAutoComplete();
      this.updateValueInChilds();
    });
    this.configureErrorMessages();
  }
  /**
   * Configure error messages of the component
   */
  configureErrorMessages() {
    if (this.fieldName === '') {
      this.fieldName = this.label;
    }
    if (this.controlContainer) {
      if (this.controlContainer.control?.get(this.formControlName)?.status === 'INVALID') {
        this.setErrors();
      }
      this.valueSub = this.controlContainer.control?.get(this.formControlName)?.valueChanges.subscribe(() => {
        this.setErrors();
      });
      this.statusSub = this.controlContainer.control?.get(this.formControlName)?.statusChanges.subscribe(() => {
        this.setErrors();
      });
    }
  }

  /**
   * Set errors of the reactive form
   */
  setErrors() {
    this.controlErrors = this.controlContainer.control?.get(this.formControlName)?.errors;
    if (!this.controlErrors) {
      if (this.value && this.itemSelectedDontExist) {
        this.controlContainer.control?.get(this.formControlName)?.setErrors({ required: true });
        this.controlErrors = this.controlContainer.control?.get(this.formControlName)?.errors;
      }
    }
    if (this.controlErrors) {
      const errors: any[] = [];
      Object.keys(this.controlErrors).forEach((key) => {
        if (this.controlErrors[key]) {
          errors.push(key);
        }
      });
      this.errors = errors;
    } else {
      this.errors = [];
    }
  }
  /**
   * Basic configuration for autocomplete
   */
  configureAutoComplete() {
    if (this.content.length === 0) {
      this.noItems = true;
    } else {
      this.noItems = false;
      this.content.forEach((element) => {
        element.multiple = this.multiple;
        const subscription = element.itemSelected.subscribe(() => {
          this.selectItem({ item: element, itemClicked: true });
        });
        this.itemsSelectedSub.push(subscription);
      });
      if (this.usingPagination) {
        if (!this.focused) {
          const item = this.content.find((itemObject) => this.value === itemObject.value);
          if (item) {
            this.selectItem({ item, preventOnTouch: true });
          }
        }
      } else {
        if (!this.multiple) {
          const item = this.content.find((itemObject) => this.value === itemObject.value);
          if (item) {
            this.selectItem({ item, preventOnTouch: true });
          }
        } else {
          this.updateValueInChilds();
        }
      }
    }
    this.changeDetectroRef.detectChanges();
  }

  /**
   * Select an item an emit the value to the parent
   */
  selectItem(config: {
    item: any;
    itemClicked?: boolean;
    matchFormKeyboardMultiple?: boolean;
    preventOnChange?: boolean;
    preventOnTouch?: boolean;
  }) {
    const { item, matchFormKeyboardMultiple, itemClicked, preventOnChange, preventOnTouch } = config;
    if (itemClicked && item && item.value === this.value) {
      return;
    }
    if (!this.multiple) {
      if (!item) {
        this.value = null;
        this.inputComponent.value = '';
        if (!preventOnTouch) {
          this.onTouch();
        }
        if (!preventOnChange) {
          this.onChange(null);
          this.valueChange.emit(null);
        }
        this.updateValueInChilds();
        return;
      }
      this.value = item.value;
      this.inputComponent.value = item.name;
      if (itemClicked) {
        this.showOptions = false;
      }
      this.updateValueInChilds();
      if (!preventOnTouch) {
        this.onTouch();
      }
      if (!preventOnChange) {
        this.onChange(this.value);
        this.valueChange.emit(this.value);
      }
    } else {
      if (item) {
        if (!this.value) {
          this.value = [];
        }
        if (this.value.length === 0) {
          this.value.push(item.value);
        } else {
          const index = this.value.indexOf(item.value);
          if (index === -1) {
            this.value.push(item.value);
          } else {
            if (!matchFormKeyboardMultiple) {
              this.value.splice(index, 1);
            }
          }
        }
      }

      this.updateValueInChilds();
      if (!preventOnTouch) {
        this.onTouch();
      }
      if (!preventOnChange) {
        this.onChange(this.value);
        this.valueChange.emit(this.value);
      }
    }
  }

  /**
   * Unselects the item we want
   * @param item item to be unselected
   */
  unSelectItem(item: any) {
    this.selectItem({ item });
  }

  /**
   * Resets the value
   */
  resetValue() {
    this.value = this.multiple ? [] : '';
    this.updateValueInChilds();
  }

  /**
   * Filter picker while writing
   * @param inputValue string value tha emits the input
   */
  autocompleteFilter(inputValue: string) {
    if (this.usingPagination || this.disable || this.readonly) {
      return;
    }
    if (!inputValue || inputValue === '') {
      this.noResults = false;
      for (const option of this.content) {
        option.hidden = false;
      }
    } else {
      let countResults = 0;
      for (const option of this.content) {
        if (option.name && !option.name.toLowerCase().includes(inputValue.toLowerCase())) {
          option.hidden = true;
        } else {
          countResults++;
          option.hidden = false;
        }
      }
      if (countResults === 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
        const optionMatch = this.content
          .filter((option) => !option.hidden)
          .find((option) => option.name.toLowerCase() === inputValue.toLowerCase());
        if (optionMatch) {
          if (this.multiple) {
            this.selectItem({ item: optionMatch, matchFormKeyboardMultiple: true });
          } else {
            this.selectItem({ item: optionMatch });
          }
        }
      }
    }
    this.showOptions = true;
  }

  /**
   * Filter on click when is using API
   */
  filterOnClick() {
    if (this.usingPagination) {
      this.clickFilter.emit(this.inputComponent.value);
      setTimeout(() => {
        this.showOptions = true;
      }, 0);
    }
  }

  toggleSelectAll(selectAll: boolean) {
    if (this.multiple) {
      if (selectAll) {
        this.value = this.content.map((item) => item.value);
        this.updateValueInChilds();
        this.onTouch();
        this.onChange(this.value);
        this.valueChange.emit(this.value);
      } else {
        this.value = [];
        this.updateValueInChilds();
        this.onTouch();
        this.onChange(this.value);
        this.valueChange.emit(this.value);
      }
    }
  }

  /**
   * Updates the selection
   * @param data to know if excute on change value
   */
  updateValueInChilds() {
    if (this.multiple) {
      this.updateOptionsSelectionMultiple();
    } else {
      this.updateOptionSelected();
    }
  }

  /**
   * Update the selected options when is multiple
   */
  updateOptionsSelectionMultiple() {
    this.content?.forEach((element) => {
      const index = this.selectedItems.findIndex((item) => item.value === element.value);
      if (this.value && this.value.includes(element.value)) {
        element.selected = true;
        if (index === -1) {
          this.selectedItems.push(element);
        }
      } else {
        element.selected = false;
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        }
      }
      if (this.selectedItems.length === 0) {
        this.inputComponent.placeholder = this.defaultPlaceholder;
      } else {
        if (!this.showItemsCount) {
          this.inputComponent.placeholder = this.selectedItems.map((item) => item.name).join(', ');
        }
      }
      if (this.showItemsCount && this.value && this.value.length) {
        this.inputComponent.placeholder = `${this.value.length} ${this.itemsName}${
          this.value.length === 1 ? '' : 's'
        } selected`;
      }
      element.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Update the selected option
   */
  updateOptionSelected() {
    let selectedItem: any;
    this.content?.forEach((element) => {
      if (this.value && this.value === element.value) {
        element.selected = true;
        selectedItem = element;
        if (this.usingPagination) {
          if (!this.focused) {
            this.inputComponent.value = element.name;
          }
        } else {
          this.inputComponent.value = element.name;
        }
      } else {
        element.selected = false;
      }
      this.itemSelectedDontExist = !selectedItem;
      element.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Resets the value
   */
  reset() {
    this.selectItem({ item: null });
    this.placeholder = this.defaultPlaceholder;
    this.inputComponent.value = '';
  }

  /**
   * Unsubscribes
   */
  ngOnDestroy(): void {
    this.selectOptionChangesSub.unsubscribe();
    this.itemsSelectedSub.forEach((sub) => {
      sub.unsubscribe();
    });
    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }
  }
}
