import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class NavigationService {
  constructor(private router: Router) {}

  navigateToDashboard() {
    if (window.location.pathname === '/dashboard/campaigns') {
      window.location.reload();
    } else {
      this.router.navigateByUrl('/dashboard/campaigns');
    }
  }
}
