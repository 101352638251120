import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.BASE_URL;
const SALES_PORTAL_BASE_URL = environment.SALES_PORTAL_BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class SalesPortalService {

  /**
   *
   * @param http http client to send http requests
   * @param router Object to manage the routing
   */
  constructor(private http: HttpClient) { /**/ }

  getAdvertisers(salesRepId: string) {
    return this.http.get<any[]>(`${SALES_PORTAL_BASE_URL}/accounts?sales_rep_id=${salesRepId}`);
  }

  setAdvertisersList(advertisersList: any[]) {
    localStorage.setItem('advertisersList', JSON.stringify(advertisersList));
  }

  get advertisersList() {
    return JSON.parse(localStorage.getItem('advertisersList') || '[]');
  }

  loginAdvertiserAccount(account_id: string) {
    return this.http.post(`${SALES_PORTAL_BASE_URL}/auth/sso`, { account_id });
  }

  logBackSalesRepAccount(user_id: string ) {
    return this.http.post(`${SALES_PORTAL_BASE_URL}/auth/sso`, { user_id });
  }
}
