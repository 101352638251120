import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from './shared/components/alert/services/alert-controller.service';
import { AdvertiserAccountController } from './shared/components/create-advertiser-account/services/advertiser-account-controller.service';
import { PopUpController } from './shared/components/pop-up/services/pop-up-controller.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked {
  title = 'telematedAdNetwork';
  hideNavbar: boolean = false;

  urlsWithoutFooter = ['/ad-network', '/campaign/build-campaign', '/campaign/campaigns'];
  constructor(
    public router: Router,
    public popUpController: PopUpController,
    public alertController: AlertController,
    private changeDetectorRef: ChangeDetectorRef,
    public advertiserAccountController: AdvertiserAccountController
  ) {}

  ngAfterContentChecked(): void {
    this.hideNavbar = window.location.pathname === '/login';
    this.changeDetectorRef.detectChanges();
  }
}
