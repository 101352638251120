import { ContentChild, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TooltipContentComponent } from '../components/tooltip-content/tooltip-content.component';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input() openOnClick = false;

  @ContentChild(TooltipContentComponent) tooltipContentComponent!: TooltipContentComponent;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.style.cursor = 'pointer';
  }

  /**
   * toggles add event listeners
   * @param action tells to add or remove
   */
  toggleListeners(action: 'add' | 'remove') {
    this.toggleWheelListener(action);
    this.toggleTouchListener(action);
  }
  /**
   * Touch listener add to hide the picker or remove for better performance
   * @param action add or remove on touch listener
   */
  toggleTouchListener(action: 'add' | 'remove') {
    if (action === 'add') {
      document.addEventListener('touchstart', this.onTouchListener);
    } else {
      document.removeEventListener('touchstart', this.onTouchListener);
    }
  }

  onTouchListener = (event: any) => {
    if (this.tooltipContentComponent && this.tooltipContentComponent.tooltipContent) {
      if (!this.tooltipContentComponent.tooltipContent.nativeElement.contains(event.target)) {
        this.tooltipContentComponent.showTooltip = false;
        this.toggleTouchListener('remove');
      }
    }
  };

  /**
   * Wheel listener add to hide the picker or remove for better performance
   * @param action add or remove on Wheel listener
   */
  toggleWheelListener(action: 'add' | 'remove') {
    if (action === 'add') {
      document.addEventListener('wheel', this.onWheelListener);
    } else {
      document.removeEventListener('wheel', this.onWheelListener);
    }
  }

  onWheelListener = (event: any) => {
    if (this.tooltipContentComponent && this.tooltipContentComponent.tooltipContent) {
      if (!this.tooltipContentComponent.tooltipContent.nativeElement.contains(event.target)) {
        this.tooltipContentComponent.showTooltip = false;
        this.toggleWheelListener('remove');
      }
    }
  };

  @HostListener('document:mousedown', ['$event'])
  click(event: any) {
    if (!this.tooltipContentComponent) {
      return;
    }
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.tooltipContentComponent.openedOnClick = false;
      this.tooltipContentComponent.showTooltip = false;
      this.toggleListeners('remove');
    } else {
      this.tooltipContentComponent.openedOnClick = true;
      this.showTooltip();
    }
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.openOnClick) {
      return;
    }
    this.tooltipContentComponent.openedOnClick = false;
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseOut(): void {
    this.hideTooltip();
  }

  showTooltip() {
    if (!this.tooltipContentComponent || this.tooltipContentComponent?.showTooltip) {
      return;
    }
    this.toggleListeners('add');
    this.tooltipContentComponent.showTooltip = true;
    setTimeout(() => {
      const rect = this.elementRef.nativeElement.getBoundingClientRect();
      this.tooltipContentComponent.top =
        rect.top +
        parseInt(this.tooltipContentComponent.moveToTop) -
        this.tooltipContentComponent.tooltipContent?.nativeElement.clientHeight +
        'px';
      this.tooltipContentComponent.left =
        rect.left +
        parseInt(this.tooltipContentComponent.moveToLeft) -
        this.tooltipContentComponent.tooltipContent?.nativeElement.clientWidth / 2 +
        this.elementRef.nativeElement.clientWidth / 2 +
        'px';
    }, 0);
  }

  hideTooltip() {
    if (!this.tooltipContentComponent || this.tooltipContentComponent?.openedOnClick) {
      return;
    }
    this.toggleListeners('remove');
    this.tooltipContentComponent.showTooltip = false;
  }

  ngOnDestroy(): void {
    this.toggleListeners('remove');
  }
}
