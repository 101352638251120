import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar.component';
import { RouterModule } from '@angular/router';
import { DropdownContainerModule } from 'src/app/shared/components/dropdown-container/dropdown-container.module';
import { MatchUrlPipe } from './pipes/match-url.pipe';
import { SelectModule } from "../../shared/components/select/select.module";
import { FormsModule} from '@angular/forms';
import { AutocompleteModule } from "../../shared/components/autocomplete/autocomplete.module";
import { TooltipModule } from "../../shared/components/tooltip/tooltip.module";

@NgModule({
    declarations: [NavbarComponent, MatchUrlPipe],
    exports: [NavbarComponent],
    imports: [
        CommonModule,
        RouterModule,
        DropdownContainerModule,
        SelectModule,
        FormsModule,
        AutocompleteModule,
        TooltipModule
    ]
})
export class NavbarModule {}
