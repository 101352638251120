import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { AccountService } from 'src/app/account/services/account.service';
import { AlertController } from 'src/app/shared/components/alert/services/alert-controller.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  /**
   *
   * @param authService Service to use a CRUD of routes POST/GET/PUT/DELETE
   */
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private alertController: AlertController,
    private router: Router
  ) {}

  /**
   * method that checks if the route can be activated
   */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHandler();
  }
  /**
   * method that checks if the route can be loaded
   */
  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHandler();
  }

  /**
   * method that checks if there´s an existing token
   */
  guardHandler() {
    // validate local storage information
    const user_id = this.accountService.user_id;
    if (!user_id) {
      this.authService.logout();
      return false;
    }

    if (localStorage.getItem('token') && localStorage.getItem('user_id')) {
      return true;
    } else {
      this.authService.logout();
      return false;
    }
  }

  showInactiveAlert() {
    this.alertController.handleError({
      title: 'Welcome!',
      msg: 'Your account is pending approval. <p>You’ll get an email confirmation when our team verifies your account.</p>',
      iconColor: 'primary-opacity',
      iconName: 'bx bxs-user-pin',
      buttons: [
        {
          buttonText: 'OK',
          buttonColor: 'primary',
          buttonHandler: () => {
            this.authService.logout();
            this.alertController.hideAlert();
          },
        },
      ],
    });
  }
}
