import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Output() toolTipShown = new EventEmitter();
  @Input('showTooltip') set showTooltipHandler(value: boolean) {
    this.fadeIn = value;
    this.showTooltip = value;
  }
  showTooltip = false;
  @Input() left = '-27px';
  @Input() bottom = '50px';
  @Input() top = '';
  @Input() width = '150px';
  @Input() fadeOut = false;
  @Input() showOrigin = true;
  @Input() boxShadow = false;
  @Input() animationFadeIn = true;
  @Input() fadeIn = false;
  @Input() openOnClick = false;
  @Input() maxHeightContent = '95px';
  @Input() heightContent = '';
  outsideClicked = false;

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showTooltip = false;
      this.outsideClicked = false;
    }
  }

  openOnClicked(event: Event) {
    event.stopPropagation();
    this.outsideClicked = true;
    this.toolTipShown.emit(true);
  }

  overToolTip() {
    if (this.openOnClick) {
      return;
    }
    this.toolTipShown.emit(true);
    this.showTooltip = true;
  }
  notOverTooltip() {
    if (this.outsideClicked) {
      return;
    }
    this.showTooltip = false;
  }
}
