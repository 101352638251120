import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { SelectModule } from '../select/select.module';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, ReactiveFormsModule, TooltipModule, SelectModule],
  exports: [InputComponent],
})
export class InputModule {}
