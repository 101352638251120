import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tooltip-content',
  templateUrl: './tooltip-content.component.html',
  styleUrls: ['./tooltip-content.component.scss'],
})
export class TooltipContentComponent {
  @ViewChild('tooltipContent') tooltipContent!: ElementRef<HTMLElement>;
  showTooltip = false;
  @Input() moveToTop = '-10px';
  @Input() moveToLeft = '0px';
  @Input() width = '150px';
  @Input() showOrigin = true;
  @Input() boxShadow = true;
  @Input() maxHeightContent = '100px';
  @Input() heightContent = 'max-content';

  openedOnClick = false;

  top = '';
  left = '';
}
