import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const SALES_PORTAL_BASE_URL = environment.SALES_PORTAL_BASE_URL;

@Injectable({
  providedIn: 'root',
})

export class AdvertiserAccountController {

  /**
   *
   * @param http injection to use API services
   */
  constructor(private http: HttpClient) {}

  showAdvertiserAccountForm: boolean = false;

  openAdvertiserAccountForm() {
    this.showAdvertiserAccountForm = true;
  }

  closeAdvertiserAccountForm() {
    this.showAdvertiserAccountForm = false;
  }

  createAdvertiserAccount(body: {company_name: string, sales_rep_id: string, use_checkout: boolean }) {
    return this.http.post(`${SALES_PORTAL_BASE_URL}/accounts`, body);
  }

  createAdvertiserUser(body: {
    account_id: string,
    email_address: string,
    first_name: string,
    last_name: string,
    type: string,
    platforms: string[],
  }) {
    return this.http.post(`${SALES_PORTAL_BASE_URL}/users`, body);
  }
}