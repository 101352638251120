import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnDestroy,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    CurrencyPipe,
    DecimalPipe,
  ],
})
export class InputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  /**Access to native input element */
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  /**Access to the start content container of the input like icons or images */
  @ViewChild('startContent') startContent!: ElementRef<HTMLDivElement>;
  /**Access to the end content container of the input like icons or images */
  @ViewChild('endContent') endContent!: ElementRef<HTMLDivElement>;

  /**Tells parent when value changes */
  @Output() valueChange = new EventEmitter();
  /**Sends parent on input event */
  @Output() inputChange = new EventEmitter();
  /**Sends parent on key down pressed event */
  @Output() keyDown = new EventEmitter();
  /**Sends parent on key up pressed event */
  @Output() keyUp = new EventEmitter();
  /**Sends parent on focus event */
  @Output() focus = new EventEmitter();
  /**Sends parent on blur event */
  @Output() blur = new EventEmitter();

  /**Sets input background */
  @Input() background = 'transparent';
  /**Sets input type */
  @Input() type = 'text';
  /**Sets input label */
  @Input() label = '';
  /**Sets input placeholder */
  @Input() placeholder: string | number = '';
  /**Sets input value */
  @Input() value = '';
  /**Sets input readonly attribute */
  @Input() readonly = false;
  /**Sets input name attribute */
  @Input() name = '';
  /**Sets input disabled attribute */
  @Input() disable = false;
  /**Sets input width */
  @Input() width = '100%';
  /**Sets input padding */
  @Input() padding = '0 5px';
  /**Sets input fontFamily */
  @Input() fontFamily = '';
  /**Sets input fontSize */
  @Input() fontSize = '14px';
  /**Sets input formControlName to reactive forms errors */
  @Input() formControlName = '';
  /**Sets input field name to reactive forms errors */
  @Input() fieldName = '';
  /**Sets custom error messages for reactive forms validators */
  @Input() errorMessages: any = {};
  /**Flag to know if shoe or hide component error messages to set them outisde component */
  @Input() showErrorMessages = true;
  /**Sets errors of reactive forms*/
  @Input() errors: any[] = [];
  /** to know if auto complete parent its invalid */
  @Input() invalidParent: boolean | undefined = false;
  /** to know if show errors under input or in a tooltip*/
  @Input() messageErrorsType: 'classic' | 'tooltip' = 'tooltip';
  /** variables to set tooltip error size*/
  @Input() tooltipErrorWidth = '150px';
  /** variable to show tooltip error origin*/
  @Input() tooltipErrorShowOrigin = true;
  /**Variable to know if when input is type number, show spin button ro increase or decrease value */
  @Input() spinButton = false;
  /**Sets browser autcomplete */
  @Input() autocomplete: 'on' | 'off' = 'on';
  /**Sets number format */
  @Input() locale = 'en-US';
  /**Sets input currency symbol to format */
  @Input() currencySymbol = '';
  /**Sets currency display */
  @Input() currencyDisplay: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol';
  /**Sets country calling code to phone format */
  @Input() countryCallingCode = '';
  /**Sets maxLength to input type text */
  @Input() maxLength = '';
  /**Sets max and min to input type number */
  @Input() min!: number;
  @Input() max!: number;
  /**Sets max and min to input type number */
  @Input() minDecimalValue = 0;
  @Input() maxDecimalValue = 99999999;
  /**Sets a pattern to match value while the user is writting */
  @Input() pattern = '';
  /**Decimal places for number type */
  @Input() decimalSpots!: number;

  /**Reactive form control errors to show them in the input*/
  controlErrors!: ValidationErrors;
  /**Reactive form control value sub */
  valueSub: Subscription | undefined = new Subscription();
  /**Reactive form control status sub */
  statusSub: Subscription | undefined = new Subscription();
  /**Flag to know if input is focused */
  isFocused = false;
  /**Stores current key pressed */
  currentKeyPressed = '';
  /**Stores current caret position */
  caretPosition = 0;

  /**
   *
   * @param controlContainer access control container reactive form
   * @param currencyPipe access currency pipe
   * @param decimalPipe access decimal pipe
   */
  constructor(
    @Optional() @Host() @SkipSelf() public controlContainer: ControlContainer,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe
  ) {}

  /**
   * After component inits
   */
  ngAfterViewInit(): void {
    this.configureErrorMessages();
  }

  /**
   * Detect when input is invalid
   */
  get invalid() {
    return (
      (this.showErrorMessages &&
        this.errors.length > 0 &&
        this.controlContainer?.control?.get(this.formControlName)?.invalid &&
        this.controlContainer?.control?.get(this.formControlName)?.touched) ||
      this.invalidParent
    );
  }

  /**
   * Detect if input has star content like icons or images
   */
  get startContentExist() {
    return this.startContent?.nativeElement.children.length > 0;
  }

  /**
   * Detect if input has end content like icons or images
   */
  get endContentExist() {
    return this.endContent?.nativeElement.children.length > 0;
  }

  /**
   * Set error messages when value changes or status changes
   */
  configureErrorMessages() {
    if (this.fieldName === '') {
      this.fieldName = this.label;
    }
    if (this.controlContainer) {
      this.setErrors();
      this.valueSub = this.controlContainer.control?.get(this.formControlName)?.valueChanges.subscribe(() => {
        this.setErrors();
      });
      this.statusSub = this.controlContainer.control?.get(this.formControlName)?.statusChanges.subscribe(() => {
        this.setErrors();
      });
    }
  }

  /**
   * Store error messages in variable
   */
  setErrors() {
    this.controlErrors = this.controlContainer.control?.get(this.formControlName)?.errors as any;
    if (this.controlErrors) {
      const errors: any[] = [];
      Object.keys(this.controlErrors).forEach((key) => {
        if (this.controlErrors[key]) {
          errors.push(key);
        }
      });
      this.errors = errors;
    } else {
      this.errors = [];
    }
  }
  /**
   * Methods of value accessor interface
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: any) => {
    //not implemented
  };
  onTouch: any = () => {
    //not implemented
  };
  writeValue(value: any): void {
    this.value = value?.toString() || '';
    if (this.value) {
      this.checkNumberFormat();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Executes on blur
   * @param event blur event
   */
  onBlur(event: any) {
    this.blur.emit(event);
    this.isFocused = false;
    this.onTouch();
    this.checkNumberFormat();
  }

  /**
   * Executes on focus
   * @param event focus event
   */
  onFocus(event: any) {
    this.isFocused = true;
    this.focus.emit(event);
  }

  /**
   *  Executes on key down
   * @param event key down event
   */
  onKeyDown(event: any) {
    this.currentKeyPressed = event.key;
    this.caretPosition = event.target.selectionStart;
    this.keyDown.emit(event);
  }

  /**
   *  Executes on key up
   * @param event key up event
   */
  onKeyUp(event: any) {
    this.keyUp.emit(event);
  }

  /**
   * Executes on input
   * @param event input event
   */
  onInput(event: any) {
    this.onTouch();
    const currentValue = this.value;

    if (this.maxLength && event.target.value.length > Number(this.maxLength)) {
      event.target.value = currentValue;
    } else {
      if (this.decimalSpots) {
        if (event.target.value.split('.')[1] && event.target.value.split('.')[1].length > this.decimalSpots) {
          event.target.value = currentValue;
        }
      }
    }

    if (this.pattern !== '') {
      this.regexValidator(event, currentValue);
    }
    if (this.type === 'number') {
      this.numberFormat(event, currentValue);
    }
    if (this.type === 'positiveNumber') {
      this.positiveNumberFormat(event, currentValue);
    }
    if (this.type === 'currency' || this.type === 'decimal') {
      this.decimalNumberFormat(event, currentValue);
    }
    if (this.type === 'phone') {
      this.phoneFormat(event);
    }
    const value = event.target.value;
    this.value = value;
    this.onChange(this.value);
    this.valueChange.emit(value);
    this.inputChange.emit(event);
  }

  /**
   * Regex validator format on input
   * @param event  on input event that contains the value to be verified
   * @param currentValue value before formated
   */
  regexValidator(event: any, currentValue: any) {
    const reg = new RegExp(this.pattern);
    if (event.target.value !== '' && !reg.test(event.target.value)) {
      event.target.value = currentValue;
    }
  }

  /**
   * Positive number format on input
   * @param event on input event that contains the value to be formated
   * @param currentValue value before formated
   */
  numberFormat(event: any, currentValue: any) {
    const reg = new RegExp(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/);
    if (
      (event.target.value !== '' && !reg.test(event.target.value)) ||
      Number(event.target.value) < this.min ||
      Number(event.target.value) > this.max
    ) {
      event.target.value = currentValue;
    }
  }

  /**
   * Positive number format on input
   * @param event on input event that contains the value to be formated
   * @param currentValue value before formated
   */
  positiveNumberFormat(event: any, currentValue: any) {
    const reg = new RegExp('^(0|[1-9][0-9]*)$');
    if (
      (event.target.value !== '' && !reg.test(event.target.value)) ||
      Number(event.target.value) < this.min ||
      Number(event.target.value) > this.max
    ) {
      event.target.value = currentValue;
    }
  }

  /**
   *
   * @param event on input event that contains the value to be formated
   * @param currentValue value before formated
   */
  decimalNumberFormat(event: any, currentValue: any) {
    if (this.currencyIsNaN(event.target.value)) {
      event.target.value = event.target.value.replace(this.currencySymbol, '');
    }
    if (
      Number(event.target.value.replace(/[^0-9.-]+/g, '')) >= this.minDecimalValue &&
      Number(event.target.value.replace(/[^0-9.-]+/g, '')) <= this.maxDecimalValue
    ) {
      let digitsInfo = '1.1-2';

      if (event.target.value[event.target.value.length - 1] === '.' || !event.target.value.includes('.')) {
        if (this.currentKeyPressed === 'Backspace' || !event.target.value.includes('.')) {
          digitsInfo = '1.0-2';
        }
      }

      if (this.min || this.min === 0) {
        if (Number(event.target.value.replace(/[^0-9.-]+/g, '')) < this.min) {
          event.target.value = currentValue;
        }
      }

      if (Number(event.target.value.replace(/[^0-9.-]+/g, '')) > this.max) {
        event.target.value = currentValue;
      }

      event.target.value = this.formatNumber(event.target.value, digitsInfo);

      if (event.target.value.substring(event.target.value.length - 2) === '.0') {
        if (this.currentKeyPressed === 'Backspace') {
          event.target.value = event.target.value.slice(0, -1);
        }
      }

      const lengthDifference = event.target.value.length - this.value.length;

      this.input.nativeElement.setSelectionRange(
        this.caretPosition + lengthDifference,
        this.caretPosition + lengthDifference
      );
    } else {
      event.target.value = currentValue;
    }
  }

  /**
   * Formats the number value into a phone numberr value
   * @param event on input event that contains the value to be formated
   */
  phoneFormat(event: any) {
    event.target.value = event.target.value.replace(this.countryCallingCode + ' ', '');
    if (event.target.value !== '') {
      event.target.value = event.target.value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      event.target.value = this.countryCallingCode
        ? this.countryCallingCode + ' ' + event.target.value
        : event.target.value;
    }
  }

  /**
   * Formats the number in decimals with or without currency
   * @param value Value to be formated
   * @param digitsInfo decimal places info
   * @returns foramted value
   */
  formatNumber(value: string, digitsInfo = '1.2-2') {
    if (this.type === 'currency') {
      value =
        this.currencyPipe.transform(
          Number(value.replace(/[^0-9.-]+/g, '')),
          this.currencySymbol,
          this.currencyDisplay,
          digitsInfo,
          this.locale
        ) || '';
    } else if (this.type === 'decimal') {
      value = this.decimalPipe.transform(Number(value.replace(/[^0-9.-]+/g, '')), digitsInfo, this.locale) || '';
    }
    return value;
  }

  /**
   * Sometimes the value is NaN beause of currencies, this methods checks if the value is NaN
   * @param value value to be verified
   * @returns boolean if value is NaN
   */
  currencyIsNaN(value: string): boolean {
    return isNaN(Number(value.toString().replace(/[^0-9.-]+/g, '')));
  }

  /**
   * Formats the number on write value or on blur
   */
  checkNumberFormat() {
    if (this.value) {
      if (this.type === 'currency' || this.type === 'decimal') {
        if (this.type === 'currency') {
          if (this.currencyIsNaN(this.value)) {
            this.value = this.value.replace(this.currencySymbol, '');
          }
          this.value = this.formatNumber(this.value);
        } else {
          this.value = this.formatNumber(this.value);
        }
      }
    }
  }

  /**
   * Unsubscribes
   */
  ngOnDestroy(): void {
    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }
  }
}
