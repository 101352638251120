import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-autocomplete-option',
  templateUrl: './autocomplete-option.component.html',
  styleUrls: ['./autocomplete-option.component.scss'],
})
export class AutocompleteOptionComponent {
  @Input() value!: string;
  @Input() name!: string;
  multiple = false;
  over!: boolean;
  valueParent: any | any[];
  selected = false;
  hidden = false;

  @Output() itemSelected = new EventEmitter();

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  change() {
    this.itemSelected.emit(this.value);
  }
}
