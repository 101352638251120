<div class="tooltip-wrapper" (mouseover)="overToolTip()" (mouseleave)="notOverTooltip()">
  <div class="outside" (click)="openOnClicked($event)">
    <ng-content select="[outside]"></ng-content>
  </div>
  <span
    (click)="openOnClicked($event)"
    *ngIf="showTooltip"
    class="tooltip"
    [ngClass]="{
      tooltipFadeIn: fadeIn,
      tooltipFadeOut: fadeOut,
      'tooltip-origin': showOrigin,
      'tooltip-box-shadow': boxShadow
    }"
    [ngStyle]="{ left: left, width: width, bottom: bottom, top: top }">
    <div
      [ngStyle]="{
        'max-height': maxHeightContent,
        height: heightContent
      }">
      <ng-content select="[inside]"></ng-content>
    </div>
  </span>
</div>
