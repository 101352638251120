import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptorService } from './auth/interceptors/token-interceptor.service';
import { FooterModule } from './core/footer/footer.module';
import { NavbarModule } from './core/navbar/navbar.module';
import { AlertModule } from './shared/components/alert/alert.module';
import { ContentModule } from './shared/components/content/content.module';
import { PopUpModule } from './shared/components/pop-up/pop-up.module';

import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { AdvertiserAccountFormComponent } from './shared/components/create-advertiser-account/components/advertiser-account-form/advertiser-account-form.component';
import { InputModule } from "./shared/components/input/input.module";
import { ButtonModule } from "./shared/components/button/button.module";
import { SpinnerModule } from "./shared/components/spinner/spinner.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CardModule } from "./shared/components/card/card.module";
import { ItemModule } from "./shared/components/item/item.module";

registerLocaleData(localeEn, 'en');

@NgModule({
    declarations: [AppComponent, AdvertiserAccountFormComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'en' },
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NavbarModule,
        FooterModule,
        HttpClientModule,
        PopUpModule,
        AlertModule,
        ContentModule,
        InputModule,
        ButtonModule,
        SpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        CardModule,
        ItemModule
    ]
})
export class AppModule {}
