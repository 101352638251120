import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from 'src/app/shared/services/ui.service';
import { AuthService } from '../../../auth/services/auth.service';
import { AdvertiserAccountController } from 'src/app/shared/components/create-advertiser-account/services/advertiser-account-controller.service';
import { SalesPortalService } from 'src/app/shared/services/sales-portal.service';
import { AlertController } from 'src/app/shared/components/alert/services/alert-controller.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  //**Open an close the mobile menu */
  showMobileItems = false;

  /** List of advertiser portal accounts */
  advertiserAccounts: any[] | null = null;

  advertiserAccountId: string | null = null;

  //**Variables to change the menu depending on the route */
  urlsMain = ['/dashboard/build-campaign', '/dashboard/campaigns', '/dashboard/campaign-detail', '/account'];
  urlsAuth = ['/sign-up', '/forgot'];

  //**Principal routes */
  routes: any[] = [
    {
      url: '/dashboard/campaigns',
      name: 'DASHBOARD',
    },
    {
      url: '/dashboard/build-campaign',
      name: 'BUILD CAMPAIGN',
      associatedUrls: ['/dashboard/campaign-detail'],
    },
    {
      url: '/account',
      name: 'ACCOUNT',
    },
    {
      url: '/login',
      name: 'LOGOUT',
    },
  ];

  /**Open an close principal routes when the navbar is mobile */
  showPrincipalMobileRoutes = true;

  /**
   *
   * @param router to manage the routing of the app
   * @param uiService to manage the modals
   */
  constructor(
    public router: Router,
    public uiService: UiService,
    private authService: AuthService,
    private advertiserAccountController: AdvertiserAccountController,
    private salesPortalService: SalesPortalService,
    private alertController: AlertController,
    private navigationService: NavigationService
    ) {}

    ngDoCheck() {
      if (localStorage.getItem('advertisersList') && !this.advertiserAccounts) {
        this.advertiserAccounts = this.salesPortalService.advertisersList;
      }

      if (!this.advertiserAccountId) {
        this.advertiserAccountId = localStorage.getItem('advertiser_account_id') ||
          localStorage.getItem('user_id') || '';
      }
    }

  /**
   * Method to navigate when the page is mobile
   * @param route to know which page go
   */
  navigateMobile(route: any) {
    if (route.url === '/login') {
      this.authService.logout();
    }

    if (route.subRouteInfo) {
      //open subroutes in mobiles
      route.subRouteInfo.openMobile = route.subRouteInfo.openMobile ? false : true;
      this.showPrincipalMobileRoutes = false;
    } else {
      this.router.navigateByUrl(route.url);
      this.showMobileItems = false;
    }
  }

  /**
   * Method to close subroutes in mobile
   */
  closeSubRoutes() {
    this.showPrincipalMobileRoutes = true;
    this.routes.forEach((route) => {
      if (route.subRouteInfo) {
        route.subRouteInfo.openMobile = false;
      }
    });
  }

  /**
   * Method to navigate in deskptop screent
   * @param route object to know which page go
   * @param eventSubroute to know if comes from a subroute
   */
  navigate(route: any, eventSubroute?: any) {
    if (route.url === '/login') {
      this.authService.logout();
      return;
    }

    this.router.navigateByUrl(route.url);
    if (eventSubroute) {
      eventSubroute.stopPropagation();
    }
  }
  /**
   * Method to open and close subroutes in desktop screen
   * @param route object to know which page go
   * @param action could be open or close
   */
  toggleSubRoute(route: any, action: 'open' | 'close') {
    if (route.subRouteInfo) {
      route.subRouteInfo.openDesktop = action === 'open' ? true : false;
    }
  }

  openAdvertiserAccountModal() {
    this.advertiserAccountController.openAdvertiserAccountForm();
  }

  changeAdvertiserAccount(event: any) {
    if (event === localStorage.getItem('user_id')) {
      this.logBackSalesRepAccount();
      return;
    }

    this.salesPortalService.loginAdvertiserAccount(event).subscribe({
      next: (res: any) => {
        const companyName = this.advertiserAccounts ? this.advertiserAccounts.find(
          (account: any) => account.account_id === this.advertiserAccountId)?.company_name : '';

        localStorage.setItem('advertiser_account_id', event);
        localStorage.setItem('company_name', companyName);
        localStorage.setItem('token', res.token);
        localStorage.setItem('advertiser_user_id', res.id);
        this.navigationService.navigateToDashboard();
      },
      error: (err: any) => {
        this.alertController.handleError({
          msg: err.error?.message || 'Error getting the advertiser account',
        });
      }
    });
  }

  logBackSalesRepAccount() {
    const user_id = localStorage.getItem('user_id') || '';
    this.salesPortalService.logBackSalesRepAccount(user_id).subscribe({
      next: (res: any) => {
        localStorage.removeItem('advertiser_account_id');
        localStorage.removeItem('company_name');
        localStorage.removeItem('advertiser_user_id');
        this.advertiserAccountId = null;
        localStorage.setItem('token', res.token);
        this.navigationService.navigateToDashboard();
      },
      error: (err: any) => {
        this.alertController.handleError({
          msg: err.error?.message || 'Error going back to sales rep account',
        });
      }
    });
  }

  calculateDecoratorWidth(route: any) {
    switch (route.name) {
      case 'DASHBOARD':
        return '120';
      case 'BUILD CAMPAIGN':
        return '160';
      case 'ACCOUNT':
        return '96';
      default:
        return '120';
    }
  }
}
