<div>
  <div class="label-container">
    <label>{{ label }}</label>
    <em tooltip *ngIf="showTooltipItems && multiple && value && value.length > 0" class="bx bxs-pencil">
      <app-tooltip-content
        moveToLeft="70px"
        [width]="tooltipItemsWidth"
        [maxHeightContent]="tooltipItemsMaxHeightContent"
        [showOrigin]="tooltipItemsShowOrigin"
        [showOrigin]="tooltipErrorShowOrigin">
        <ng-container inside *ngTemplateOutlet="tooltipItemsSelected"></ng-container>
      </app-tooltip-content>
    </em>
    <ng-content select="[labelContent]"></ng-content>
  </div>
  <div class="select-container">
    <details #details (toggle)="onToggle($event)" [ngStyle]="_detailsStyles">
      <summary
        [ngStyle]="summaryStyles"
        [ngClass]="{
          'outline-danger': invalid && !disable,
          'outline-primary': !invalid && !disable,
          'outline-disable': disable
        }">
        <em
          class="bx bx-x unselect-icon"
          (click)="reset()"
          *ngIf="
            !disable && !readonly && canClearSelection && ((!multiple && value) || (multiple && value && value.length))
          "></em>
        <p
          [ngStyle]="{
            opacity: !value || value.length === 0 || disable || selectTextContent === defaultPlaceholder ? 0.7 : 1,
            overflow: 'hidden',
            'white-space': 'nowrap'
          }">
          {{ !value || value.length === 0 ? defaultPlaceholder : selectTextContent }}
        </p>
        <div class="end-icons">
          <em outside class="bx bx-chevron-down select-icon" *ngIf="!disable && !readonly && showToggleIcon"></em>
          <em
            tooltip
            *ngIf="invalid && messageErrorsType === 'tooltip'"
            class="bx bx-error-circle"
            [ngStyle]="{
              'font-size': '25px',
              color: 'var(--app-danger-color)'
            }">
            <app-tooltip-content [width]="tooltipErrorWidth" [showOrigin]="tooltipErrorShowOrigin">
              <ng-container inside *ngTemplateOutlet="errorValidators"></ng-container>
            </app-tooltip-content>
          </em>
        </div>
        <div #endContent (click)="endContentClicked = true">
          <ng-content select="[end]"></ng-content>
        </div>
      </summary>
      <div>
        <div
          class="options"
          #listContainer
          [ngStyle]="{
            top:
              distanceToBottom <= 200
                ? details.getBoundingClientRect().top - listContainer.getBoundingClientRect().height + 5 + 'px'
                : details.getBoundingClientRect().top + details.getBoundingClientRect().height + 8 + 'px',
            width: details.getBoundingClientRect().width - 2 + 'px'
          }"
          *ngIf="showOptions && !hideOptions && disable === false">
          <app-select-option *ngIf="noItems" name="No Items"></app-select-option>
          <ng-content></ng-content>
        </div>
      </div>
    </details>
  </div>
</div>
<div *ngIf="messageErrorsType === 'classic' && invalid">
  <ng-container *ngTemplateOutlet="errorValidators"></ng-container>
</div>

<ng-template #tooltipItemsSelected>
  <h4>{{ itemsName | titlecase }}{{ selectedItems.length === 1 ? '' : 's' }} Selected</h4>
  <p *ngFor="let item of selectedItems" class="selected-item">
    <em (click)="unSelectItem(item)" class="bx bx-x text-danger"></em>
    <span>{{ item.name }}</span>
  </p>
</ng-template>

<ng-template #errorValidators>
  <ng-container *ngFor="let error of errors">
    <ng-container [ngSwitch]="error">
      <p *ngSwitchCase="'required'" class="msg-validator">{{ errorMessages[error] || fieldName + ' is required.' }}</p>
      <p *ngSwitchDefault class="msg-validator">
        {{ errorMessages[error] || error + ' message not assigned.' }}
      </p>
    </ng-container>
  </ng-container>
</ng-template>
