<div>
  <div class="label-container" *ngIf="label">
    <label>{{ label }}</label>
    <em tooltip *ngIf="showTooltipItems && multiple && value && value.length > 0" class="bx bxs-pencil">
      <app-tooltip-content
        moveToLeft="60px"
        [width]="tooltipItemsWidth"
        [maxHeightContent]="tooltipItemsMaxHeightContent"
        [showOrigin]="tooltipItemsShowOrigin">
        <ng-container inside *ngTemplateOutlet="tooltipItemsSelected"></ng-container>
      </app-tooltip-content>
    </em>
    <ng-content select="[labelItem]"></ng-content>
  </div>

  <div class="input-container" #inputContainer>
    <app-input
      [tooltipErrorShowOrigin]="tooltipErrorShowOrigin"
      [invalidParent]="invalid"
      [errorMessages]="errorMessages"
      [fieldName]="fieldName ? fieldName : label"
      [errors]="errors"
      [disable]="disable"
      [readonly]="readonly"
      (blur)="onBlur()"
      (focus)="onFocus()"
      (keyUp)="onKeyUp($event)"
      (valueChange)="autocompleteFilter($event)"
      autocomplete="off"
      #inputSearch>
      <div end class="end-icons">
        <div #endContent>
          <ng-content select="[end]"></ng-content>
        </div>
        <div *ngIf="!disable && !readonly" (click)="filterOnClick()" class="bx bx-search icon-search"></div>
      </div>
    </app-input>
    <div>
      <div
        class="options"
        #listContainer
        [ngStyle]="{
          top:
            distanceToBottom <= 200
              ? inputContainer.getBoundingClientRect().top - listContainer.getBoundingClientRect().height + 5 + 'px'
              : inputContainer.getBoundingClientRect().top + inputContainer.getBoundingClientRect().height + 8 + 'px',
          width: inputContainer.getBoundingClientRect().width + 'px'
        }"
        *ngIf="showOptions && disable === false">
        <app-autocomplete-option *ngIf="noItems" name="No Items"></app-autocomplete-option>
        <app-autocomplete-option *ngIf="noResults && !noItems" name="No Results"></app-autocomplete-option>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipItemsSelected>
  <h4>Items Selected</h4>
  <p *ngFor="let item of selectedItems" class="selected-item">
    <em *ngIf="!disable" (click)="unSelectItem(item)" class="bx bx-x text-danger"></em>
    <span>{{ item.name }}</span>
  </p>
</ng-template>

<div *ngIf="messageErrorsType === 'classic' && invalid">
  <ng-container *ngTemplateOutlet="errorValidators"></ng-container>
</div>

<ng-template #errorValidators>
  <ng-container *ngFor="let error of errors">
    <ng-container [ngSwitch]="error">
      <p *ngSwitchCase="'required'" class="msg-validator">{{ errorMessages[error] || fieldName + ' is required' }}</p>
      <p *ngSwitchDefault class="msg-validator">
        {{ errorMessages[error] || error + ' message not assigned' }}
      </p>
    </ng-container>
  </ng-container>
</ng-template>
