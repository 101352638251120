import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './components/select/select.component';
import { SelectOptionComponent } from './components/select-option/select-option.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { CheckboxModule } from '../checkbox/checkbox.module';

@NgModule({
  declarations: [SelectComponent, SelectOptionComponent],
  imports: [CommonModule, TooltipModule, CheckboxModule],
  exports: [SelectComponent, SelectOptionComponent],
})
export class SelectModule {}
