import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutocompleteOptionComponent } from './components/autocomplete-option/autocomplete-option.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { InputModule } from '../input/input.module';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [AutocompleteComponent, AutocompleteOptionComponent],
  imports: [CommonModule, CheckboxModule, InputModule, TooltipModule],
  exports: [AutocompleteComponent, AutocompleteOptionComponent],
})
export class AutocompleteModule {}
