<app-navbar *ngIf="!hideNavbar"></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>

<app-pop-up *ngIf="popUpController.showPopUp"></app-pop-up>
<app-alert *ngIf="alertController.showAlert"></app-alert>
<app-alert-loading *ngIf="alertController.loading"></app-alert-loading>
<app-advertiser-account-form
  *ngIf="advertiserAccountController.showAdvertiserAccountForm"
></app-advertiser-account-form>
